import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    dayWrapper: {
        position: 'relative',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
    },
    week: {
        position: 'absolute',
        left: '-8px',
        top: '8px',
        fontSize: '0.75em',
    },
    selectedWeek: {
        border: 'solid 1px',
        borderRadius: '50px',
        borderColor: theme.palette.secondary.main,
    },
    disabled: {
        color: 'lightgray',
        pointerEvents: 'none',
    },
    highlight: {
        color: theme.palette.main,
        background: theme.palette.primary.main,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    },
}));

//let dayCapacitiesCache = {};

export default function DeliveryDatePicker(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    let enabledDays = [];

    const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
        const dateClone = moment(date);
        const selectedClone = moment(selectedDate);
        const isStartOfWeek = dateClone.day() === 1;
        const isSelected = dateClone.isSame(selectedClone);
        const isSelectedWeek = selectedClone.isoWeek() === dateClone.isoWeek();
        const isBeforeCurrentDate = dateClone.isBefore(moment(), 'day');
        const weekNumber = dateClone.isoWeek();
        // Disable all days to force user to choose a delivery method first
        let enabledDay = false;
        if (props.deliveryMethod) {
            const deliveryMethodId = props.deliveryMethod.id;
            const deliveryMethodName = props.deliveryMethod;

            if (
                ['KANERMA', 'Rahti asiakkaan oman sopimustoimittajan kautta'].includes(deliveryMethodName) ||
                [107, 114736].includes(deliveryMethodId)
            ) {
                enabledDay =
                    dateClone.day() === 1 || // monday
                    dateClone.day() === 3; // wednesday
                enabledDays = [1, 3];
            } else if (
                ['ITELLA PAKETTI', 'ITELLA LAVA'].includes(deliveryMethodName) ||
                [101, 104].includes(deliveryMethodId)
            ) {
                enabledDay =
                    dateClone.day() === 2 || // tuesday
                    dateClone.day() === 5; // friday
                enabledDays = [2, 4];
            } else if (['VARASTO SUOMI'].includes(deliveryMethodName) || [814876].includes(deliveryMethodId)) {
                enabledDay =
                    dateClone.day() === 1 || // monday
                    dateClone.day() === 2 || // tuesday
                    dateClone.day() === 3 || // wednesday
                    dateClone.day() === 4 || // thursday
                    dateClone.day() === 5; // friday
                enabledDays = [1, 2, 3, 4, 5];
            }
        }

        // Early date picker should show all weekdays as available
        if (props.enableAllWeekDays === true) {
            enabledDay =
                dateClone.day() === 1 || // monday
                dateClone.day() === 2 || // tuesday
                dateClone.day() === 3 || // wednesday
                dateClone.day() === 4 || // thursday
                dateClone.day() === 5; // friday;
            enabledDays = [1, 2, 3, 4, 5];
        }

        let canDeliver;
        if (props.latestDeliveryDate) {
            canDeliver = dateClone.isSameOrBefore(moment(props.latestDeliveryDate), 'day');
        } else {
            canDeliver = dateClone.isSameOrAfter(moment(props.earliestDeliveryDate), 'day');
        }

        const enabled = canDeliver && !isBeforeCurrentDate && enabledDay;

        const wrapperClassName = clsx(classes.dayWrapper, {
            [classes.highlight]: isSelected,
        });

        const dayClassName = clsx(classes.day, {
            [classes.disabled]: isBeforeCurrentDate || !canDeliver || !enabledDay || !dayInCurrentMonth,
        });

        const weekClassName = clsx(classes.week, {
            [classes.selectedWeek]: isSelectedWeek,
        });

        let tooltipTitle = '';
        if (!canDeliver && !isBeforeCurrentDate) {
            tooltipTitle = t('general.full');
        }

        if (!dayInCurrentMonth) {
            return (
                <div className={wrapperClassName}>
                    <IconButton className={dayClassName}>
                        <span> </span>
                    </IconButton>
                </div>
            );
        }

        if (enabledDay) {
            return (
                <Tooltip title={tooltipTitle}>
                    <div className={wrapperClassName}>
                        {isStartOfWeek && <span className={weekClassName}>{weekNumber}</span>}
                        <IconButton className={dayClassName} name={enabled ? 'btn-day-enabled' : 'btn-day-disabled'}>
                            <span> {dateClone.format('D')} </span>
                        </IconButton>
                    </div>
                </Tooltip>
            );
        }
        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {dateClone.format('D')} </span>
                </IconButton>
            </div>
        );
    };

    const shouldDisableDate = (date) => {
        const shouldDisable =
            (!props.latestDeliveryDate && moment(date).isBefore(moment(props.earliestDeliveryDate), 'day')) ||
            (props.latestDeliveryDate && moment(date).isAfter(moment(props.latestDeliveryDate), 'day')) ||
            enabledDays.length === 0 ||
            !enabledDays.includes(moment(date).day());
        return shouldDisable;
    };

    const renderText = (date, invalidLabel) => {
        const dateM = moment(date);
        return dateM.isValid() ? dateM.format('DD.MM.YYYY') + ` - viikko ${dateM.isoWeek()}` : '';
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
            <KeyboardDatePicker
                id={!!props.id ? props.id : 'deliveryTime'}
                className={classes.datePicker}
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                name={!!props.name ? props.name : 'deliveryTime'}
                label={props.label}
                value={props.value}
                onChange={props.onChange}
                invalidDateMessage={t('general.invalidDateMsg')}
                minDateMessage={t('order.msgCannotSetDeliveryDateIntoThePast')}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                renderDay={renderWrappedWeekDay}
                labelFunc={renderText}
                disablePast={true}
                shouldDisableDate={shouldDisableDate}
                onClick={props.onOpen}
                disabled={props.disabled}
            />
        </MuiPickersUtilsProvider>
    );
}
