import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CONFIG from '../config';
import Logger from '../common/logger';
import { addWsEvent } from '../modules/appstate';
import { refreshLocationFullDataIfNeeded } from '../modules/locationdata';
import { refreshCustomerFullDataIfNeeded } from '../modules/customerdata';
import { setWsConnectionId } from '../modules/authentication';
import {
    addOrUpdateProductToStore,
    removeProductFromStore,
    getFilterProducts,
    updateContractPricingProgress,
    getContractPricingUpdateStatus,
} from '../modules/productdata';
import { productionOrderChanged } from '../modules/orderdata';

// const STATE_CONNECTING = 0;
const STATE_OPEN = 1;
// const STATE_CLOSING = 2;
// const STATE_CLOSED = 3;

export default function WebSocketConnector(props) {
    const socket = useRef(null);
    const dispatch = useDispatch();

    const locations = useSelector((state) => state.locationdata.locationsById);
    const customers = useSelector((state) => state.customerdata.customersById);
    const products = useSelector((state) => state.productdata.filterProducts);
    const fetchingProducts = useSelector((state) => state.productdata.fetchingFilterProducts);
    const contractPrices = useSelector((state) => state.productdata.contractPricesByContractId);
    const productlistVersion = useSelector((state) => state.productdata.filterProductsVersion);

    const customersRef = useRef(null);
    const locationsRef = useRef(null);
    const productsRef = useRef(null);
    const fetchingProductsRef = useRef(null);
    const contractPricesRef = useRef(null);
    const productlistVersionRef = useRef(null);

    locationsRef.current = locations;
    customersRef.current = customers;
    productsRef.current = products;
    fetchingProductsRef.current = fetchingProducts;
    contractPricesRef.current = contractPrices;
    productlistVersionRef.current = productlistVersion;

    const postWsDebugEvent = (eventName) => {
        if (!CONFIG.features.debugUi) return;
        dispatch(
            addWsEvent({
                timestamp: new Date().toISOString(),
                event: eventName,
            })
        );
    };

    const onOpen = (e) => {
        postWsDebugEvent(`Opened - ${socket.current.readyState}`);
        dispatch(setWsConnectionId(undefined));
        if (socket.current.readyState === STATE_OPEN) {
            socket.current.send(
                JSON.stringify({ message: 'authenticate', data: sessionStorage.getItem('accessToken') })
            );
            postWsDebugEvent('Auth sent');
        }
    };

    const handleLocationUpdate = (msg) => {
        for (const id of msg.ids) {
            const loc = locationsRef.current[id];
            if (loc) dispatch(refreshLocationFullDataIfNeeded(loc.id, loc.storageId));
        }
    };
    const handleCustomerUpdate = (msg) => {
        for (const id of msg.ids) {
            const cust = customersRef.current[id];
            if (cust) dispatch(refreshCustomerFullDataIfNeeded(cust.id, cust.storageId));
        }
    };
    const handleProductUpdate = (msg) => {
        const payload = JSON.parse(msg.payload);
        const newVersion = payload.productListVersion;
        const oldVersion = productlistVersionRef.current;

        // Can do incremental list update
        if (oldVersion + 1 === newVersion) {
            if (['update', 'insert'].includes(msg.type)) {
                dispatch(addOrUpdateProductToStore(payload.product, newVersion));
            } else if (msg.type === 'remove') {
                dispatch(removeProductFromStore(payload.product, newVersion));
            } else {
                Logger.LogError(`Unknown update type:${msg.type}`);
            }
        } else {
            if (!fetchingProductsRef.current) {
                dispatch(getFilterProducts());
            }
        }
    };

    const handleProductionOrderChange = (msg) => {
        const payload = JSON.parse(msg.payload);
        const order = payload.order;
        dispatch(productionOrderChanged(order));
    };

    const handleContractPriceUpdate = (msg) => {
        //
        // TODO
        //
    };

    const handleContractPricingProgressUpdate = (msg) => {
        const payload = JSON.parse(msg.payload);
        dispatch(updateContractPricingProgress(payload));
        if (payload && payload.updateFinished) {
            dispatch(getContractPricingUpdateStatus());
        }
    };

    const onMessage = (e) => {
        const msg = JSON.parse(e.data);
        console.log(msg);
        switch (msg.message) {
            case 'authenticated':
                postWsDebugEvent(`Authenticated - conn: ${msg.connectionId}`);
                dispatch(setWsConnectionId(msg.connectionId));
                break;
            case 'pong':
                postWsDebugEvent('Ping-pong');
                break;
            case 'dataupdate':
                postWsDebugEvent(`dataupdate / ${msg.scope}-${msg.type}: ${msg.ids}`);
                if (msg.scope === 'location') {
                    handleLocationUpdate(msg);
                } else if (msg.scope === 'customer') {
                    handleCustomerUpdate(msg);
                } else if (msg.scope === 'product') {
                    handleProductUpdate(msg);
                } else if (msg.scope === 'productlist') {
                } else if (msg.scope === 'contractprice') {
                    handleContractPriceUpdate(msg);
                } else if (msg.scope === 'productionorder') {
                    handleProductionOrderChange(msg);
                } else if (msg.scope === 'contractpricingprogressupdate') {
                    handleContractPricingProgressUpdate(msg);
                }
                break;
            default:
                postWsDebugEvent(`unknown msg: ${msg}`);
                break;
        }
    };
    const onClose = (e) => {
        postWsDebugEvent(`Closed - ${socket.current.readyState}`);
        dispatch(setWsConnectionId(undefined));
        setTimeout(() => {
            openConnectionIfAuthenticated();
        }, 1000);
    };
    const onError = (e) => {
        postWsDebugEvent(`ERROR - ${socket.current.readyState}`);
        dispatch(setWsConnectionId(undefined));
        setTimeout(() => {
            openConnectionIfAuthenticated();
        }, 2000);
    };
    const openConnectionIfAuthenticated = () => {
        if (
            props.authenticated &&
            sessionStorage.getItem('accessToken') != null &&
            sessionStorage.getItem('accessToken').length > 0
        ) {
            socket.current = new WebSocket(CONFIG.wsUrl);
            socket.current.onopen = onOpen;
            socket.current.onmessage = onMessage;
            socket.current.onclose = onClose;
            socket.current.onerror = onError;
        }
    };

    const pingPongSocket = () => {
        setTimeout(() => {
            if (socket.current && socket.current.readyState === STATE_OPEN) {
                socket.current.send(JSON.stringify({ message: 'ping', data: '' }));
            } else {
                openConnectionIfAuthenticated();
            }
            pingPongSocket();
        }, 120000);
    };

    const closeConnection = () => {
        if (socket.current) socket.current.close();
    };

    useEffect(() => {
        if (props.authenticated) {
            openConnectionIfAuthenticated();
            pingPongSocket();
        } else {
            closeConnection();
        }

        return function cleanup() {
            closeConnection();
        };
        // eslint-disable-next-line
    }, [props.authenticated]);

    return null;
}
