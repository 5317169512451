import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../common/tabpanel';
import Box from '@material-ui/core/Box';
import { filter } from 'lodash';
import ContractPrices from './contractprices';
import FilterProduct from '../domain/filterproduct';
import ProductMaintenance from './productmaintenance';
import ProductRequests from './productrequests';
import AllProducts from './allproducts';
import ProductBundleList from './productbundlelist';
import { ActionAllowed } from '../rbac/rbacutil';
import { rbacactions } from '../rbac/rbacrules';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

const parseUrlHash = (urlHash) => {
    const hash = urlHash.split('-');
    const hashTab = parseInt(hash[1], 10);
    if (hashTab && hashTab >= 0 && hashTab < 5) {
        return hashTab;
    }
    return 0;
};

export default function ProductList(props) {
    const productList = useSelector((state) => state.productdata.filterProducts);
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const [productRequests, setProductRequests] = useState([]);
    const classes = useStyles();
    const history = useHistory();
    const [tab, setTab] = useState(parseUrlHash(history.location.hash));
    const { t } = useTranslation();

    useEffect(() => {
        if (productList && productList.length > 0) {
            setProductRequests(
                filter(productList, (p) => p.toBeReplaced || p.status === FilterProduct.StatusDraftRequest())
            );
        }
    }, [productList]);

    useEffect(() => {
        history.replace(`/products#tab-${tab}`);
    }, [tab, history]);

    useEffect(() => {
        const newTab = parseUrlHash(history.location.hash);
        setTab(newTab);
    }, [history.location.hash]);

    const handleTabChange = (event, value) => {
        setTab(value);
    };

    const productRequestCount = () => {
        return productRequests.length;
    };

    if (productList == null) return <div>Ladataan...</div>;
    return (
        <div className={classes.root}>
            <Grid container spacing={3} alignItems={'flex-end'} alignContent={'flex-end'} justifyContent={'flex-end'}>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Box display="flex" justifyContent="center" width="100%">
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto">
                                <Tab
                                    value={0}
                                    label={t('general.all') + ' (' + (productList ? productList.length : '-') + ')'}
                                />
                                <Tab value={1} label={t('general.bundles')} />
                                <Tab
                                    value={2}
                                    label={t('product.productRequests') + ' (' + productRequestCount() + ')'}
                                />
                                <Tab value={3} label={t('product.contractPrices')} />
                                <Tab
                                    value={4}
                                    label={t('product.productUpdates')}
                                    disabled={!ActionAllowed(rbacactions.MassUpdateProducts, userProfile)}
                                />
                            </Tabs>
                        </Box>
                    </AppBar>
                    <TabPanel value={tab} index={0}>
                        <AllProducts />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <ProductBundleList />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <ProductRequests productRequests={productRequests} />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <ContractPrices active={tab === 3} />
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <ProductMaintenance history={history} active={tab === 4} />
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    );
}
